import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { GroupPageLayout } from '../components/layouts'

class PagesGroupTemplate extends React.Component {
    render() {
        const group = get(this.props, 'data.contentfulPageGroup')
        let sitemap = get(this.props, 'data.allContentfulWidgetSitemap.edges', [])
        sitemap = sitemap.map(link => link && link.node)
        return (
            <GroupPageLayout group={group} sitemap={sitemap} />
        )
    }
}

export default PagesGroupTemplate

export const pageQuery = graphql`
query PagesGroupBySlug($slug: String!) {
    contentfulPageGroup(slug: { eq: $slug }) {
        __typename
        title
        userSection
        orderOption
        slug
        orderOption
        goToPage
        headerImage {
            file {
                url
            }
            title
        }
        headerVideo
        headerImageFullWidth
        body {
            childContentfulRichText {
                internal {
                    content
                }
                html
            }
        }
        image {
            title
            file {
                url
            }
        }
        groups {
            slug
            title
            contentful_id
            userSection
        }
        summary {
            summary
        }
        page__generic {
            __typename
            contentful_id
            title
            slug
            summary{
                summary
            }
            goToPage
            userSection
            createdAt
            layout {
                layout
            }
        }
        page__group {
            __typename
            title
            slug
            summary{
                summary
            }
            goToPage
            userSection
            createdAt
        }
        page__widgets {
            __typename
            title
            slug
            userSection
            goToPage
            createdAt
        }
        metaDescription {
            metaDescription
        }
        orderedList {
            ... on ContentfulPageGeneric {
                __typename
                contentful_id
                title
                slug
                summary{
                    summary
                }
                goToPage
                userSection
                createdAt
                layout {
                    layout
                }
            }
            ... on ContentfulPageGroup {
                __typename
                title
                slug
                summary{
                    summary
                }
                goToPage
                userSection
                createdAt
            }
            ... on ContentfulPageWidgets {
                __typename
                title
                slug
                userSection
                goToPage
                createdAt
            }
        }
        sections {
            name
            fullWidth
            widgets {
                __typename
                ... on ContentfulPageGroup {
                    contentful_id
                    title
                    slug
                    userSection
                    headerImage {
                        file {
                            url
                        }
                    }
                    image {
                        file {
                            url
                        }
                    }
                    imagePosition
                    groups {
                        __typename
                        title
                        slug
                    }
                    page__generic {
                        __typename
                        title
                        slug
                        userSection
                    }
                    page__group {
                        __typename
                        title
                        slug
                        userSection
                    }
                    page__widgets {
                        __typename
                        title
                        slug
                        userSection
                    }
                }
                ... on ContentfulWidgetSearchPanel {
                    contentful_id
                    title
                    description {
                        description
                    }
                    bgImage {
                        title
                        file {
                            url
                        }
                    }
                    showCategories
                }
                ... on ContentfulWidgetStoryBlock {
                    contentful_id
                    title
                    summary {
                        summary
                    }
                    backgroundImage {
                        title
                        file {
                            url
                        }
                    }
                    donateAmount
                    story {
                        __typename
                        slug
                        layout {
                            layout
                        }
                        pageDonation {
                            slug
                        }
                    }
                    recurring
                    donateReason
                }
                ... on ContentfulWidgetHero {
                    contentful_id
                    title
                    searchText
                    type
                    summary {
                        childContentfulRichText {
                            internal {
                                content
                            }
                            html
                        }
                    }
                    backgroundImage {
                        title
                        file {
                            url
                        }
                    }
                    featureList {
                        __typename
                        ... on ContentfulWidgetStoryBlock {
                            title
                            slug
                            backgroundImage {
                                title
                                file {
                                    url
                                }
                            }
                            story {
                                __typename
                                slug
                                layout {
                                    layout
                                }
                            }
                        }
                        ... on ContentfulWidgetLinkBlock {
                            __typename
                            title
                            slug
                            backgroundImage {
                                title
                                file {
                                    url
                                }
                            }
                            featureLink: link {
                                __typename
                                ... on ContentfulPageGeneric {
                                    title
                                    slug
                                    userSection
                                    layout {
                                        layout
                                    }
                                }
                                ... on ContentfulPageGuideline {
                                    title
                                    slug
                                }
                                ... on ContentfulWidgetLink {
                                    text
                                    url
                                }
                                ... on ContentfulPageWidgets {
                                    title
                                    slug
                                    userSection
                                }
                                ... on ContentfulPageGroup {
                                    title
                                    slug
                                    userSection
                                }
                            }
                            icon
                        }
                    }
                    link {
                        text
                        url
                    }
                }
                ... on ContentfulWidgetLinks {
                    __typename
                    title
                    description {
                        description
                    }
                    backgroundColor
                    textColor
                    imagePosition
                    image {
                        title
                        file {
                            url
                        }
                    }
                    links {
                        __typename
                        ... on ContentfulPageGeneric {
                            title
                            slug
                            userSection
                            layout {
                                layout
                            }
                        }
                        ... on ContentfulPageGuideline {
                            title
                            slug
                        }
                        ... on ContentfulWidgetLink {
                            text
                            url
                        }
                    }
                }
                ... on ContentfulWidgetRichText {
                    contentful_id
                    content {
                        childContentfulRichText {
                            internal {
                                content
                            }
                            html
                        }
                    }
                }
                ... on ContentfulWidgetLinkBlock {
                    __typename
                    title
                    slug
                    summary {
                        summary
                    }
                    backgroundImage {
                        file {
                            url
                        }
                    }
                    buttonText
                    buttonLink: link {
                        __typename
                        ... on ContentfulPageGeneric {
                            title
                            slug
                            userSection
                            layout {
                                layout
                            }
                        }
                        ... on ContentfulPageGuideline {
                            title
                            slug
                        }
                        ... on ContentfulWidgetLink {
                            text
                            url
                        }
                        ... on ContentfulPageWidgets {
                            title
                            slug
                            userSection
                        }
                        ... on ContentfulPageGroup {
                            title
                            slug
                            userSection
                        }
                    }
                }
                ...ImpactWidget
            }
        }
    }
    allContentfulWidgetSitemap(filter:{title: {ne: "Don't delete - sitemap"}}) {
        totalCount
        edges {
            node {
                contentful_id
                userSection
                title
                slug
                links {
                    __typename
                    ... on ContentfulPageCustom {
                        slug
                        title
                        contentful_id
                        userSection
                    }
                    ... on ContentfulPageDirectoryOfServices {
                        id
                        title
                    }
                    ... on ContentfulPageDonation {
                        slug
                        title
                        contentful_id
                    }
                    ... on ContentfulPageGeneric {
                        slug
                        title
                        userSection
                    }
                    ... on ContentfulPageGroup {
                        slug
                        title
                        userSection
                    }
                    ... on ContentfulPageGuideline {
                        title
                        slug
                    }
                    ... on ContentfulPageWidgets {
                        title
                        slug
                        userSection
                    }
                }
            }
        }
    }
}
`
